<template>
  <section class="gardenManagementPage">
    <SearchForm class="dis-flex flex-x-start flex-y-start">
      <el-button type="success" @click="add" class="turnBule">添加内容</el-button>
    </SearchForm>

    <TableContainer title="">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        border
        :header-cell-style="tabHeader"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="id" label="id" min-width="30" />
        <!-- <el-table-column prop="content" label="类别" min-width="30" /> -->
        <el-table-column prop="title" label="标题" min-width="30" />
        <!-- <el-table-column prop="classname" label="排序" min-width="30" /> -->
        <el-table-column width="500" label="操作">
          <template slot-scope="scope">
            <el-button type="text" class="textOrange" @click="editorClick(scope.row)"
              >编辑</el-button
            >
            <!-- <el-button type="text" class="textOrange" @click="delteClick(scope.row)"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <GPagination
        v-show="total > 0"
        ref="ref_GPagination"
        :total="total"
        :page.sync="searchForm.page"
        :size.sync="searchForm.size"
        @pagination="querycshopmallContent"
        :page-sizes="pageSizes"
      />
    </TableContainer>

    <!-- 添加分类s -->
    <GDialog
      :dialog.sync="themeDialog"
      @btnSure="submitThemeForm()"
      @btnClose="resetThemeForm()"
      width="70%"
    >
      <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
        <el-form-item label="标题" label-width="100px">
          <div style="width: 220px">
            <el-input v-model.trim="title" placeholder="请输入内容标题" type="text" />
          </div>
        </el-form-item>
        <!-- <el-form-item label="排序" label-width="100px">
          <div style="width: 220px">
            <el-input v-model.trim="classname" placeholder="请输入排序数字" type="text" />
          </div>
        </el-form-item> -->
        <Tinymce :height="600" v-model="tiny.value"></Tinymce>
      </el-form>
    </GDialog>
    <!-- 添加分类e -->
  </section>
</template>

<script>
import {
  querycshopmallContent,
  updatecshopmallContent,
  addcshopmallContent,
  delcshopmallContent,
} from "@api/bx/api_contentManagement";
import Tinymce from "./tinymce";
export default {
  components: { Tinymce },
  data() {
    return {
      themeDialog: {
        title: "添加",
        visible: false,
      },
      tiny: {
        value: undefined,
      },
      searchForm: {
        content: '',
        title: '',

      },
      total: 0,
      tableData: [],
      pageSizes: [15, 30, 45, 60, 75],
      title: "",
      editor: false,
      row: {},
    };
  },
  created() {
    this.querycshopmallContent();
  },
  methods: {
    tabHeader() {
      return "backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;";
    },

    // 列表
    querycshopmallContent() {
      querycshopmallContent(this.searchForm).then((res) => {
        this.tableData = res;
        // this.total = res.total;
      });
    },

    //添加
    add() {
      this.editor = false;
      this.themeDialog.visible = true;
    },
    //确定
    submitThemeForm() {
      if (this.title == "") {
        this.$alert("请输入标题", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.tiny.value == undefined) {
        this.$alert("请输入内容", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.editor) {
        updatecshopmallContent({
          title: this.title,
          id: this.row.id,
          content:this.tiny.value
        }).then((res) => {
          this.title = "";
          this.themeDialog.visible = false;
          this.querycshopmallContent();
        });
      } else {
        addcshopmallContent({
          title: this.title,
          content:this.tiny.value
        }).then((res) => {
          this.title = "";
          this.themeDialog.visible = false;
          this.querycshopmallContent();
        });
      }
    },

    //取消按钮
    resetThemeForm() {
      this.themeDialog.visible = false;
      this.classname = "";
    },
    //编辑
    editorClick(row) {
      this.editor = true;
      this.title = row.title;
      this.tiny.value = row.content;
      this.row = row;
      this.themeDialog.visible = true;
    },
    // //删除
    delteClick(row) {
      this.$confirm("永久删除" + row.title + "这条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delcshopmallContent({ id: row.id }).then((res) => {
          this.querycshopmallContent();
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
  color: #333;
  background-color: #58e3e8;
  border-color: rgba(56, 185, 190, 0.12);
  margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
  background: rgba(56, 185, 190, 0.5);
  border-color: rgba(56, 185, 190, 0.5);
  color: #fff;
}

.el-button--text.reviewed {
  color: #38b9be;
}

.reviewed {
  color: #38b9be;
}

.el-tree-node__content {
  z-index: 999;
  padding-left: 40px;
}

.directory-in {
  width: 32%;
  line-height: 60px;
  background: #4095e5;
}
</style>
<style>
.tox-tinymce-aux {
  z-index: 3333333;
}
</style>
